import Vue from 'vue'
import { validationMixin } from 'vuelidate';

export default {

	mixins: [validationMixin],

	data: function() {

		return {
			is: {
				loading: true,
				error: false,
				saving: false,
				initialised: false
			},
			enableStash: false,
			item: [],
			callbacks: [],
			original: []
		}

	},

	created: function() {

		if (this.setDefaults) this.setDefaults()

		this.load()

	},

	computed: {

		stashName: function() {

			return this.$route.path

		},

		title: function() {

			return (this.isNew) ? 'Create' : this.item.name
		
		},

		isLoading: function() {

			return this.is.loading

		},

		isValid: function() {

			return !this.$v.$invalid

		},

		isNew: function() {

			return this.$route.params.id === 'new' && !this.model.id

		},

		isDirty: function() {

			return !this.$_.isEqual(this.model, this.original)

		}

	},

	beforeRouteEnter: function(to, from, next) {

		/*if (from.meta.stash === to.name) {

			next(vm => {

				vm.enableStash = true

				Vue.set(vm, 'original', vm.$util.copy(vm.model))
				
				if (vm.$store.getters['stash'][vm.stashName] && vm.enableStash) {

					Vue.set(vm, 'model', vm.$util.copy(vm.$store.getters['stash'][vm.stashName]))
		
				}

				vm.runCallbacks()

			})

		} else {*/

			next(vm => {

				vm.runCallbacks()

			})

		//}

	},

	beforeRouteLeave: function(to, from, next) {

		/*if (to.meta.stash === from.name || this.isDirty) {

			this.$store.commit('stash', {
				name: this.stashName,
				data: this.$util.copy(this.model)
			})

		} 

		if (this.isDirty && !to.meta.stash) {
			
			next({
				name: from.name.replace('.Edit', '.Unsaved'),
				params: {
					id: this.model.id || this.$route.params.id
				},
				query: {
					redirect: to.path
				}
			})

		} else [*/

			next()

		//]

	},

	watch: {

		model: {

			deep: true,
			handler: function() {

				this.$v.$touch()

			}

		}

	},

	methods: {

		runCallbacks: function() {

			this.$_.each(this.callbacks, function(callback) {

				if (this.$route.params[callback]) this[callback](this.$route.params[callback])

			}.bind(this))

		},

		clearStash: function() {

			/*this.$store.commit('stash', {
				name: this.stashName,
				data: false
			})*/

		},

		load: function() {

			if (this.isNew) {

				if (!this.enableStash) Vue.set(this, 'original', this.$util.copy(this.model))
				
				this.is.loading = false
				this.is.initialised = true

			} else {

				this.is.loading = true

				this.$api.get([this.$endpoint(this.endpoint), this.model.id || this.$route.params.id]).then(function(json) {

					Vue.set(this, 'item', this.$util.copy(json))
					if (!this.enableStash) Vue.set(this, 'model', this.$util.copy(json))
					Vue.set(this, 'original', this.$util.copy(json))

					this.is.loading = false
					this.is.initialised = true

				}.bind(this))
				
			}

		},

		onSaveClick: function() {

			this.$v.$touch()

			if (this.$v.$invalid) {

				this.is.error = true

			} else {
				
				this.is.error = false

				// this.clearStash()

				this.is.saving = true
	
				this.$api[(this.isNew) ? 'post' : 'put']([this.$endpoint(this.endpoint), this.model.id || this.$route.params.id], this.model).then(function(json) {

					this.$notify({
						message: ['You have successfully', this.isNew ? 'created' : 'saved', 'the', this.noun, '<b>', this.model.name, '</b>.']
					})

					Vue.set(this, 'item', this.$util.copy(json))
					Vue.set(this, 'model', this.$util.copy(json))
					Vue.set(this, 'original', this.$util.copy(this.model))

					this.is.saving = false

				}.bind(this), function() {

					this.$notify({
						message: ['Sorry, this', this.noun, 'could not be saved. Please try again.'],
						type: 'error'
					})

					this.is.saving = false

				}.bind(this))

			}

		},

		onUndoClick: function() {

			Vue.set(this, 'model', this.$util.copy(this.original))

		},

		onDeleteClick: function() {

			this.$router.push({
				name: this.$route.name + '.Delete',
				params: {
					id: this.model.id || this.$route.params.id
				}
			})

		},

		saveURL: function(value) {

			if (this.$_.isArray(value)) value = value.join('/')

			var url = [this.$endpoint(this.endpoint), this.model.id || this.$route.params.id]
			
			if (value) url.push(value)

			return url

		}

	}

}